import { SortOrder } from 'src/Types/SortOrder'
import type { AssetWithAlarms } from 'src/Types/AssetWithAlarms'
import type { OpenedAlarm } from 'src/Types/OpenedAlarm'

import { getLatestAlarmTime } from 'src/Utils/alarms'
import { WindowStatusValue } from 'src/Types/AssetTypes'

const IGNORED_ASSET_STATUS_ORDER = [
  WindowStatusValue.IrregularData,
  WindowStatusValue.NoContact,
  WindowStatusValue.Out_of_Order,
  WindowStatusValue.Maintenance,
  'NotMonitored',
]

/**
 * Sort assets by name
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const sortByName = (a: AssetWithAlarms, b: AssetWithAlarms): number => a.asset.name.localeCompare(b.asset.name)

/**
 * Sort assets by the latest alarm date
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const sortByLatestAlarmDate = (a: AssetWithAlarms, b: AssetWithAlarms): number => {
  const latestA = getLatestAlarmTime(a.alarms)
  const latestB = getLatestAlarmTime(b.alarms)
  if (latestA !== undefined && latestB !== undefined) {
    return latestB - latestA
  }
  if (latestA === undefined && latestB === undefined) {
    return 0
  }
  return latestA === undefined ? 1 : -1
}

/**
 * Sort assets by criticality
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const sortByCriticality = (a: AssetWithAlarms, b: AssetWithAlarms): number =>
  b.asset.totalCriticalityAbsolute - a.asset.totalCriticalityAbsolute

/**
 * Sort assets by kilometer
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const sortByKilometer = (a: AssetWithAlarms, b: AssetWithAlarms): number => {
  if (a.asset.fromKm && b.asset.fromKm) {
    return a.asset.fromKm - b.asset.fromKm
  }
  return a.asset.baneDataLocationName.localeCompare(b.asset.baneDataLocationName) || sortByName(a, b)
}

/**
 * Sort assets by ignored status, pushing ignored statuses to the bottom
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const sortIgnoredAssetStatusToBottom = (a: AssetWithAlarms, b: AssetWithAlarms): number =>
  IGNORED_ASSET_STATUS_ORDER.indexOf(a.asset?.statusWithAssetStatusWindows || '') -
  IGNORED_ASSET_STATUS_ORDER.indexOf(b.asset?.statusWithAssetStatusWindows || '')

/**
 * Combined sorting function for latest alarm date and ignored status
 * @param {AssetWithAlarms} a
 * @param {AssetWithAlarms} b
 * @returns {number}
 */
const combinedSortByLatestAlarmDateAndIgnoredStatus = (a: AssetWithAlarms, b: AssetWithAlarms): number =>
  sortByLatestAlarmDate(a, b) || sortIgnoredAssetStatusToBottom(a, b)

/**
 * Group cards by showCriticality then sort by criticality,
 * and those that don't showCriticality are sorted by latest alarm date and ignored status
 * @param {AssetWithAlarms[]} assetsWithAlarms
 * @returns {AssetWithAlarms[]}
 */
const groupOnShowCriticalityThenSortDifferently = (assetsWithAlarms: AssetWithAlarms[]): AssetWithAlarms[] => {
  const thoseShowingCriticality = assetsWithAlarms.filter(a => a.asset.showCriticality)
  const thoseNotShowingCriticality = assetsWithAlarms.filter(a => !a.asset.showCriticality)

  return [
    ...thoseShowingCriticality.sort(sortByCriticality),
    ...thoseNotShowingCriticality.sort(combinedSortByLatestAlarmDateAndIgnoredStatus),
  ]
}

/**
 * Group cards by unopened alarms, then sort by latest alarm date
 * @param {AssetWithAlarms[]} assetsWithAlarms
 * @param {OpenedAlarm[]} openedAlarms
 * @returns {AssetWithAlarms[]}
 */
const groupOnUnopenedCardsThenSortByLatestAlarm = (
  assetsWithAlarms: AssetWithAlarms[],
  openedAlarms: OpenedAlarm[]
): AssetWithAlarms[] => {
  const unopenedCards = assetsWithAlarms.filter(card => !openedAlarms.some(oa => oa.baneDataId === card.asset.baneDataId))
  const openedCards = assetsWithAlarms.filter(card => openedAlarms.some(oa => oa.baneDataId === card.asset.baneDataId))

  return [...unopenedCards.sort(sortByLatestAlarmDate), ...openedCards.sort(sortByLatestAlarmDate)]
}

/**
 * Main export function to sort assets with alarms based on the specified sort order
 * @param {AssetWithAlarms[]} assetsWithAlarms
 * @param {SortOrder} sortOrder
 * @param {OpenedAlarm[]} openedAlarms
 * @returns {AssetWithAlarms[]}
 */
export const sortAssetsWithAlarms = (
  assetsWithAlarms: AssetWithAlarms[],
  sortOrder: SortOrder,
  openedAlarms: OpenedAlarm[]
): AssetWithAlarms[] => {
  switch (sortOrder) {
    case SortOrder.LatestAlarm:
      return assetsWithAlarms.sort(sortByLatestAlarmDate)

    case SortOrder.Criticality:
      return groupOnShowCriticalityThenSortDifferently(assetsWithAlarms)

    case SortOrder.Unopened:
      return groupOnUnopenedCardsThenSortByLatestAlarm(assetsWithAlarms, openedAlarms)

    case SortOrder.Kilometer:
      return assetsWithAlarms.sort(sortByKilometer)

    case SortOrder.Alphabetical:
    default:
      return assetsWithAlarms.sort(sortByName)
  }
}
